import { FC, useCallback } from "react";
import styles from "./index.module.scss";
import logoImage from "../../assets/images/logo.svg";
import { handleRedirect } from "../../app/util";
import {
  discordLink,
  gitbookLink,
  githubLink,
  mediumLink,
  telegramLink,
  twitterLink,
} from "../../app/config";
import { useNavigate } from "react-router-dom";

const Header: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (_url?: string) => {
      if (_url) {
        navigate?.(_url);
      }
    },
    [navigate]
  );

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <div className={styles.logo} onClick={() => handleNavigate("/")}>
          <img className={styles.logoImage} src={logoImage} alt="" />
        </div>
        <div className={styles.list}>
          <svg
            className={styles.item}
            onClick={() => handleRedirect(twitterLink)}
          >
            <use xlinkHref="#icon-x" />
          </svg>
          <svg
            className={styles.item}
            onClick={() => handleRedirect(discordLink)}
          >
            <use xlinkHref="#icon-discord" />
          </svg>
          <svg
            className={styles.item}
            onClick={() => handleRedirect(telegramLink)}
          >
            <use xlinkHref="#icon-telegram" />
          </svg>
          {/* <svg
            className={styles.item}
            onClick={() => handleRedirect(mediumLink)}
          >
            <use xlinkHref="#icon-medium" />
          </svg> */}
          {/* <svg
            className={styles.item}
            onClick={() => handleRedirect(gitbookLink)}
          >
            <use xlinkHref="#icon-Gitbook" />
          </svg> */}
          {/* <svg
            className={styles.item}
            onClick={() => handleRedirect(githubLink)}
          >
            <use xlinkHref="#icon-Github" />
          </svg> */}
        </div>
        <div className={styles.divider} />
      </div>
    </div>
  );
};

export default Header;
