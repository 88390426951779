export const twitterLink = "https://twitter.com/ambrosia_fi";
export const discordLink = "https://discord.gg/5z9yzRFuCs";
export const telegramLink = "https://t.me/ambrosiaLabs";
export const mediumLink = "https://medium.com/@web3ambVM";
export const gitbookLink = "https://docs.ambrosialabs.io/";
export const githubLink = "https://github.com/IOAmbrosiaLabs";
export const ambroLink = "https://www.ambro-crypto.fr/";
export const victorLink =
  "https://www.linkedin.com/in/victor-demonchaux-%F0%9F%8C%BF-4a7689139/";
export const remiLink = "https://www.linkedin.com/in/remitelenczak/";
export const kevinLink = "https://www.linkedin.com/in/kevin-poujol-60740811b/";
