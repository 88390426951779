import { FC } from "react";
import styles from "./index.module.scss";

const Top: FC = () => {
  return (
    <div className={styles.verification}>
      <svg className={styles.verificationIcon}>
        <use xlinkHref="#icon-lock" />
      </svg>
      <div className={styles.verificationValue}>
        <span className={styles.verificationLabel}>
          URL verification:&nbsp;
        </span>
        <span
          className={styles.verificationText}
          onClick={() => {
            window.location.href = "https://ambrosia-finance.fr/";
          }}
        >
          <span className={styles.verificationTexts}>https://</span>
          ambrosia-finance.fr/
        </span>
        <span className={styles.verificationLabel}>&nbsp;or&nbsp;</span>
        <span
          className={styles.verificationText}
          onClick={() => {
            window.location.href = "https://ambrosialabs.io/";
          }}
        >
          <span className={styles.verificationTexts}>https://</span>
          ambrosialabs.io/
        </span>
      </div>
    </div>
  );
};

export default Top;
