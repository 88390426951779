import { FC, useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import video from "../../assets/images/video.webm";
import victorImage from "../../assets/images/victor.png";
import remiImage from "../../assets/images/remi.png";
import kevinImage from "../../assets/images/Kevin.png";
import teamImage from "../../assets/images/team.png";
import ambrosiaLabsImage from "../../assets/images/ambrosia_labs.svg";
import binanceLabsImage from "../../assets/images/binance_labs.png";
import { handleRedirect } from "../../app/util";

import { gitbookLink, kevinLink, remiLink, victorLink } from "../../app/config";
import Header from "../../components/Header";
import Top from "../../components/Top";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";

const Home: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.play();

      videoElement.addEventListener("pause", () => {
        videoElement.play();
      });

      videoElement.addEventListener("ended", () => {
        videoElement.play();
      });
    }
  }, []);

  const handleNavigate = useCallback(
    (_url?: string) => {
      if (_url) {
        navigate?.(_url);
      }
    },
    [navigate]
  );

  return (
    <div className={styles.body}>
      <Top />
      <Header />
      <div className={styles.banner}>
        <video
          className={styles.video}
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={video} type="video/webm" />
        </video>
      </div>
      <div className={styles.panel}>
        <span className={styles.panelText}>WE ARE BACKED BY</span>
        <img className={styles.panelImage} src={binanceLabsImage} alt="" />
      </div>
    </div>
  );
};

export default Home;
